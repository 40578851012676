<template>
  <v-dialog v-model="dialog" width="650">
    <template v-slot:activator="{ on }">
      <v-col cols="12" align="center">
        <base-border-card
          :title="$t('buy')"
          @card_clicked="
            open;
            dialog = true;
          "
        >
        </base-border-card>
      </v-col>
    </template>
    <v-card>
      <v-card-title> {{ $t("buy_message_packs") }}</v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row justify="center">
          <v-col cols="7" md="4" v-for="pack in packs" :key="pack.id">
            <v-card class="border-card" @click="purchase(pack)">
              <v-card-text>
                <h3
                  align="center"
                  class="primary--text text-center text-uppercase mb-1 font-weight-regular"
                >
                  {{ pack.name }}
                </h3>
                <h1 align="center" class="mb-1 font-weight-regular py-4">
                  {{ $n(pack.price, "currency_no_icon") }}€
                </h1>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "PurchaseChannelPackage",
  props: ["channel"],
  data() {
    return {
      dialog: false,
      packs: [],
    };
  },
  mounted() {
    this.open();
  },
  methods: {
    ...mapActions("notifications", ["getPacks", "purchaseNotificationPack"]),
    ...mapActions("plans", ["checkUserPaymentMethods"]),
    open() {
      console.log("opening");
      this.getPacks({ filters: { channel_id: this.channel.id } }).then(
        (response) => {
          this.packs = response;
        }
      );
    },
    purchase(pack) {
      this.$confirm(
        `¿Estas seguro que quieres comprar el ${pack.name} por ${this.$n(
          pack.price,
          "currency_no_icon"
        )}€?`
      ).then(() => {
        this.checkUserPaymentMethods().then((data) => {
          if (data.check)
            this.purchaseNotificationPack(pack.id).then(() => {
              this.dialog = false;
              this.$alert("Pack compradocorrectamente");
              this.$emit("update");
            });
          else {
            this.$alert(this.$t("billing.no_pm"), "", "");
          }
        });
      });
    },
  },
};
</script>
